import React from "react";
import CompanyLogo from "./CompanyLogo";
import ProfileMenu from "../features/profilemenu";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WebsiteHeader = ({ onNavToggleButtonPressed }) => {
    return (
        <header className="bg-white p-3 flex flex-nowrap items-center sticky top-0 z-20 w-full max-h-16">
            <div className="flex justify-start w-6/12">
                <button 
                    className="border py-2 px-4 md:hidden"
                    onClick={onNavToggleButtonPressed}
                >
                    <FontAwesomeIcon icon="fa-bars" />
                </button>
                <NavLink to="/dashboard">
                    <CompanyLogo className="w-48" />
                </NavLink>
            </div>
            <div className="hidden md:flex justify-end items-center w-6/12">
                <ProfileMenu />
            </div>
        </header>
    );
};

export default WebsiteHeader;