import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import TotalWealthDisplay from "./TotalWealthDisplay";

const PortfolioValuationViewSelector = ({ currentTab, setCurrentTab }) => {
    const PortfolioValuationViews = [
        { id: 1, name: "Investments Summary" },
        { id: 2, name: "Investments Summary by Share Class" },
        { id: 3, name: "Investments Summary by Volatility" },
        { id: 4, name: "Investments Detailed by Owner" },
        { id: 5, name: "Investments Detailed by Investment Structure" },
        { id: 6, name: "Investments Detailed by Units & Shares" },
    ];


return (
    <div class="container mx-auto grid md:grid-cols-2 grid-cols-1 gap-4">
        <div class="flex flex-col bg-white border-2 p-4">
            <Listbox as="div"
                        value={currentTab}
                        onChange={setCurrentTab}
                        className="space-y-1"
                        >
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700">
                                    Report Style
                                </Listbox.Label>

                                <div className="relative mt-1">
                                    <span className="inline-block w-full rounded-md shadow-sm">
                                        <Listbox.Button className="inline-flex w-full text-small items-center px-3 border border-slate-300 rounded">
                                            <span className="block truncate">
                                                {currentTab
                                                    ? currentTab.name
                                                    : "Select.."}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <svg
                                                    className="h-5 w-5 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </Listbox.Button>
                                    </span>

                                    <Transition
                                        as={Fragment}
                                        show={open}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    
                                    >
                                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border border-slate-300 z-[1000]">
                                            {PortfolioValuationViews.map((el, elIdx) => (
                                                <Listbox.Option 
                                                    key={elIdx} 
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-5 pr-4 ${
                                                        active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={el}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                        <span
                                                            className={`block truncate ${
                                                                selected ? 'font-medium' : 'font-normal'
                                                            }`}
                                                        >
                                                            {el.name}
                                                        </span>
                                                        
                                                        </>
                                                    )}
                                                    
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
            </Listbox>
        </div>
        <div class="flex flex-col bg-white border-2 p-4">
            <TotalWealthDisplay />
        </div>
    </div>
);

};

export default PortfolioValuationViewSelector;
