import React, { useState } from "react";
import WebsiteFooter from "./WebsiteFooter";
import WebsiteHeader from "./WebsiteHeader";
import WebsiteNavigation, { WebsiteNavigationDrawer } from "./WebsiteNavigation";
import { Outlet } from "react-router-dom";

const WebsiteLayout = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    
    const onNavToggle = () => setIsNavOpen((prevState) => !prevState);

    return (
        <React.Fragment>
            <WebsiteHeader onNavToggleButtonPressed={onNavToggle} />
            <WebsiteNavigation />
            <main className="container mx-auto mb-3 px-3 md:px-0 flex-1">
                <Outlet />
            </main>
            <WebsiteFooter />
            <WebsiteNavigationDrawer isOpen={isNavOpen} toggleDrawer={onNavToggle} />
        </React.Fragment>
    )
};

export default WebsiteLayout;