import React from "react";
import Accordion, { StaticAccordion } from "../../../components/Accordion";
import usePortfolioInvestmentsByUnitsAndShares from "../hooks/usePortfolioInvestmentsByUnitsAndShares";
import Money from "../../../components/Money";
import LoadingSkeleton from "./LoadingSkeleton";
import TotalWealthDisplay from "./TotalWealthDisplay";
import Moment from "react-moment";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import PortfolioValuationInvestmentSummaryWrapper from "./PortfolioValuationInvestmentsSummaryWrapper";

const PortfolioValuationInvestmentSummaryByUnitsAndShares = () => {

    const { data, error, isFetching, isLoading } = usePortfolioInvestmentsByUnitsAndShares();

    if(isLoading || isFetching){
        return(
            <PortfolioValuationInvestmentSummaryWrapper>
                <LoadingSkeleton />
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(!error && data == null || data.length <= 0){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600 text-xs lg:text-base">
                        <thead>
                            <tr className="text-blue-900">
                                <th className="md:w-[60%]"></th>
                                <th className="text-right">Reference</th>
                                <th className="text-right">Units</th>
                                <th className="text-right hidden xl:table-cell">Price Date</th>
                                <th className="text-right hidden xl:table-cell">Price</th>
                                <th className="text-right">Value</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(error){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }     

    return (
        <>
            {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <PortfolioValuationInvestmentSummaryWrapper type="accordion" title={el.description} value={el.totalFundValues} allocation={el.totalAllocation}>
                        <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600 text-xs lg:text-base">
                            <thead>
                                <tr className="text-blue-900">
                                    <th className="md:w-[60%]"></th>
                                    <th className="text-left">Reference</th>
                                    <th className="text-right">Units</th>
                                    <th className="text-right hidden xl:table-cell">Price Date</th>
                                    <th className="text-right hidden xl:table-cell">Price</th>
                                    <th className="text-right">Value</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr className="">
                                            <th colspan="4 xl:6" className="md:w-[40%] font-bold truncate">{c.description} <span className="text-gray-500">{c.subheading}</span></th>
                                        </tr>

                                        {c.investments.length > 0 && c.investments.map((inv, index) => (
                                            <tr key={index}>
                                                <td className="truncate">{inv.investmentName}</td>
                                                <td className="text-left tabular-nums">{inv.contractReference}</td>
                                                <td className="text-right tabular-nums"><Money prefix={''} value={inv.units} showNil={false} decimalPlaces={2} /></td>
                                                <td className="text-right tabular-nums hidden xl:table-cell">{inv.priceDate != null && <Moment date={inv.priceDate} format="DD/MM/YY" />} {inv.priceDate == null && <span>-</span>}</td>
                                                <td className="text-right tabular-nums hidden xl:table-cell"><Money prefix={''} value={inv.price} showNil={false}  decimalPlaces={2} />{inv.currency}</td>
                                                <td className="text-right tabular-nums"> <Money prefix={'£'} value={inv.value} showNil={false} /></td>
                                            </tr>
                                        ))}

                                        <tr className="">
                                            <th colspan="4 xl:6"  className="text-right tabular-nums"><Money prefix={'£'} value={c.totalFundValues} showNil={false} /></th>
                                        </tr>
                                    </>
                                ))}           
                            </tbody>
                        </table>
                    </PortfolioValuationInvestmentSummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default PortfolioValuationInvestmentSummaryByUnitsAndShares;